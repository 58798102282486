.content-info {
  margin-bottom: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: $footer-background;
  .site-description {
    padding-top: 0.5em;
    font-size: 1.2em;
    line-height: 1.8;
  }
  .footer-nav {
    font-family: $robotocondensed;
    font-size: 1.2em;
    letter-spacing: 0.1em;
    line-height: 2;
    li {
      a {
        color: $footer-menu-color;
        text-transform: uppercase;
        transition: all 0.2s;
        &:hover {
          color: $footer-menu-hover;
          text-decoration: none;
          transition: all 0.2s;
        }
      }
    }
  }
}