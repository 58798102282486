.post-a-task {
  .container {
    padding-bottom: 4em;
    border-bottom: 1px solid $dark-gray;
  }
  .post-a-task-box {
    .inner {
      padding: 4em;
      p {
        min-height: 180px;
        font-size: 1.8em;
        line-height: 2;
      }
      .button-group {
        .btn {
          padding: 0.5em;
          font-size: 1.8em;
          &.red {
            background-color: transparent;
            transition: all 0.3s;
            border-color: $post-a-task-button;
            &:hover {
              transition: all 0.3s;
              color: $white;
              background-color: $post-a-task-button;
            }
          }
          &.yellow {
            transition: all 0.3s;
            background-color: transparent;
            border-color: $apply-a-task;
            &:hover {
              transition: all 0.3s;
              color: $white;
              background-color: $apply-a-task;
            }
          }
        }
      }
    }
    &:first-child {
      .inner {
        /*margin-right: ;*/
        @media (min-width: $screen-md-min) {
          border-right: 1px solid $dark-gray;
        }
      }
    }
  }
}

.what-can-we-do {
  padding-top: 4em;
  a {
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  .thumbnail {
    border: none;
    background-color: transparent;
    .caption {
      h3 {
        a {
          color: #333;
          &:hover, &:focus {
            text-decoration: none;
          }
        }
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.1em;
      }
      text-align: center;
      font-size: 1.2em;
    }
  }
}

.how-it-works {
  padding-top: 4em;
  .how-it-works-tabs {
    padding-top: 2em;
    .nav-tabs {
      li {
        width: 50%;
        text-align: center;
        .active {
          border: 1px solid $dark-gray;
        }
        a {
          margin-right: 0;
          border: 1px solid $dark-gray;
          font-family: $robotocondensed;
          text-transform: uppercase;
          font-weight: 600;
          border-radius: 0;
          font-size: 2.4em;
          padding: 0.5em 0;
          letter-spacing: 0.1em;
          color: $how-it-works-tab;
        }
        &:first-child {
          a {
            border-right: 0;
          }
        }
        &.active {
          a {
            color: $how-it-works-active-tab;
          }
        }
      }
    }
    .tab-content {
      font-size: 1.8em;
      border-left: 1px solid $dark-gray;
      border-bottom: 1px solid $dark-gray;
      border-right: 1px solid $dark-gray;
      padding: 2em;
      margin-bottom: 1.5em;
      dt {
        padding-top: 2em;
        font-family: $robotocondensed;
        &:first-child {
          padding-top: 0;
        }
      }
      dd {
        line-height: 2em;
      }
    }
  }
}

.main {
  .wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
    h2 {
      color: $page-title-color;
      font-family: $robotoslab;
      font-weight: 700;
      font-size: 60px;
      text-transform: none;
      letter-spacing: 0;
      margin-bottom: 35px;
    }
    form {
      .form-errors {
        color: red;
      }
      .control-label {
        text-align: left;
        font-family: $roboto;
        font-weight: 700;
        font-size: 14px;
        color: $tasks-label-color;
      }
      input:not([type=submit]), select, textarea {
        font-family: $roboto;
        font-size: 14px;
        color: $tasks-input-pholder;
        border: 2px solid $tasks-form-border;
        border-radius: 0;
      }
      input, select {
        height: 40px;
      }
      input[type=checkbox] {
        height: 13px;
      }
      textarea {
        height: 115px;
      }
      .datetime {
        input {
          border-right: none;
        }
        .input-group-addon {
          border: 2px solid $tasks-form-border;
          border-left: none;
          background: transparent;
        }
      }
      .btn-success {
        border: 0;
        color: $white;
      }
    }
  }
  .dropdown {
    margin-bottom: 35px;
    .dropdown-toggle {
      text-align: left;
      border: 2px solid $category-selector-border;
      span {
        font-family: $robotoslab;
        font-weight: bold;
        font-size: 20px;
      }
    }
    &.open {
      .dropdown-menu {
        width: 95%;
        margin-left: 15px;
        margin-top: 0;
        border-radius: 0;
      }
    }
  }
  .task, .task-single, .user {
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid $gray;
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
    .profil {
      text-align: center;
      img {
        margin-bottom: 20px;
      }
    }
    .details {
      @media (min-width: $screen-sm-min) {
        border-right: 1px solid $gray;
      }
      h2 {
        font-size: 20px;
        margin-top: 0;
      }
      p {
        margin-bottom: 0;
        span {
          min-width: 175px;
          text-transform: uppercase;
          font-family: $robotoslab;
          font-size: 16px;
          color: $gray;
          display: inline-block;
          font-weight: bold;
        }
      }
      .description {
        margin-bottom: 10px;
      }
    }
    .actions {
      text-align: center;
      .price {
        font-family: $robotocondensed;
        font-size: 40px;
      }
      .btn-apply, .btn-suspend {
        font-size: 14px;
        display: inline-block;
        padding: 10px;
        background-color: $light-red;
        color: $white;
        text-transform: uppercase;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
      .btn-applied, .btn-activate {
        font-size: 14px;
        display: inline-block;
        padding: 10px;
        background-color: $green;
        color: $white;
        text-transform: uppercase;
        &:hover, &:focus {
          text-decoration: none;
        }
        &.btn-appliers {
          border-color: #36b53f;
        }
      }
    }
  }
  .task-single {
    border: 0;
    .details {
      border-right: 0;
      h2 {
        font-family: $roboto;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      }
      p {
        font-size: 16px;
      }
    }
    .appliers {
      h2 {
        font-family: $roboto;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 15px;
      }
      .description {
        h2 {
          font-family: $robotoslab;
          text-transform: none;
          a {
            display: none;
            font-size: 16px;
            font-weight: normal;
            padding-left: 15px;
            &:hover, &:focus {
              text-decoration: none;
            }
          }
        }
        .btn-assign {
          display: block;
          border: 2px solid $show-task-assign-btn;
          max-width: 140px;
          color: $show-task-assign-color;
          margin-bottom: 15px;
        }
        .sep {
          border-bottom: 1px solid $black;
          margin-bottom: 25px;
          max-width: 280px;
        }
      }
    }
  }
  .paid, .activation {
    padding-bottom: 70px;
    img {
      padding-top: 115px;
      padding-bottom: 45px;
    }
    h2 {
      text-transform: none;
      font-size: 60px;
    }
    p {
      font-size: 24px;
      margin-bottom: 20px;
    }
    a {
      font-size: 18px;
      background-color: $light-red;
      color: $white;
      padding: 20px 40px;
      display: block;
      max-width: 380px;
      margin: 0 auto;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
  .register {
    padding-top: 85px;
    padding-bottom: 130px;
    h2, p {
      font-family: $robotoslab;
      color: $white;
      margin-bottom: 25px;
    }
    h2 {
      font-weight: bold;
      font-size: 60px;
      text-transform: none;
      letter-spacing: 0;
    }
    p {
      font-size: 24px;
      max-width: 660px;
      margin: 0 auto 25px auto;
    }
    form {
      max-width: 385px;
      margin: 0 auto;
      .form-group {
        input {
          border-radius: 0;
          height: 55px;
          font-family: $roboto;
          font-weight: bold;
        }
        &.left {
          padding-left: 0;
          padding-right: 5px;
        }
        &.right {
          padding-right: 0;
          padding-left: 5px;
        }
      }
      .terms {
        float: left;
        label {
          float: right;
          padding-left: 10px;
          color: $white;
          font-family: $robotocondensed;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .submit-dark {
        font-family: $roboto;
        font-weight: bold;
        font-size: 18px;
        background-color: $register-submit-bg;
        color: $white;
        border-color: $register-submit-border;
      }
    }
    &.client {
      background: $register-client-bg;
    }
    &.tasker {
      background: $register-tasker-bg;
    }
  }
}

.login-form {
  .error {
    max-width: 500px;
    margin: 0 auto 10px;
    color: red;
  }
  form {
    max-width: 500px;
    margin: 0 auto;
    input {
      height: 50px;
    }
    input[type=checkbox] {
      height: 13px;
    }
    button {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-bottom: 15px;
    }
    button[type=button] {
      padding: 0;
      a {
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }
}