$robotocondensed:               'Roboto Condensed', sans-serif;
$robotoslab:                    'Roboto Slab', serif;
$roboto:                        'Roboto', sans-serif;

$brand-primary:                 #42a1d9;
$white:                         #ffffff;
$dark-gray:                     #303030;
$gray:                          #3f3f3f;
$light-gray:                    #ebebeb;
$light-red:                     #ff5a40;
$light-blue:                    #b2b02d;
$grey:                          #959595;
$green:                         #36B53F;

$white:                         #fff;
$black:                         #000;
$dark-gray:                     #2f2f2f;
$gray:                          #3f3f3f;
$light-gray:                    #4d4f4f;
$lighter-gray:                  #959595;
$lightest-gray:                 #ebebeb;
$dark-blue:                     #132f40;
$blue:                          #42a2da;
$light-red:                     #ff5a40;
$dark-yellow:                   #b2b02d;

/**
Global Color
*/
$logo-blue:                     $blue;
$client-red:                    $light-red;
$tasker-yellow:                 $dark-yellow;
$edit-btn-border:               $light-red;
$edit-btn-color:                $light-red;
$appliers-btn-bg:               $light-red;
$appliers-btn-border:           $light-red;
$page-title-color:              $light-gray;

/**
Header Color
*/
$header-menu-color:             $dark-gray;
$header-register-link-bg:       $dark-blue;
$header-login-link-border:      $blue;
$navbar-toggle:                 $blue;
$navbar-toggle-span:            $white;
$navbar-collapsed:              $blue;
$navbar-collapsed-span:         $blue;
$navbar-default-border:         $lighter-gray;
$jumbotron-button-bg:           $blue;

/**
Footer Color
*/
$footer-background:             $lightest-gray;
$footer-menu-color:             $dark-gray;
$footer-menu-hover:             $blue;

/**
Modal Color
*/
$modal-reg-tasker-nav-color:    $dark-yellow;
$modal-reg-client-nav-color:    $light-red;
$modal-reg-submit-bg:           $dark-blue;
$modal-reg-submit-border:       $dark-blue;

/**
Form Color
*/
$tasks-form-border:             $light-gray;
$tasks-label-color:             $light-gray;
$tasks-input-pholder:           $light-gray;

/**
Home Color
*/
$post-a-task-button:            $light-red;
$apply-a-task:                  $dark-yellow;
$how-it-works-active-tab:       $blue;
$how-it-works-tab:              $dark-gray;

/**
Browse Task Color
*/
$category-selector-border:      $blue;

/**
Register Page
*/
$register-client-bg:            $light-red;
$register-tasker-bg:            $dark-yellow;
$register-submit-bg:            $dark-blue;
$register-submit-border:        $dark-blue;

/**
Show Task Page
*/
$show-task-assign-btn:          $light-red;
$show-task-assign-color:        $light-red;