.main-header{
  background-size: cover;
  background-position: top center;

  .navbar-brand{
    display: table;
  }

  button.navbar-toggle{
    background-color: $navbar-toggle;
    border-radius: 0;
    border-width: 2px;
    border-style: solid;
    padding: $grid-gutter-width/2;
    transition: all 0.3s;
    &:focus{
      border-color: $navbar-toggle;
      background-color: $navbar-toggle;
    }
    span{
      width: $grid-gutter-width;
      display: block;
      margin-bottom: 4px;
      height: 4px;
      background-color: $navbar-toggle-span;
      transition: all 0.3s;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &.collapsed{
      border-color: $navbar-collapsed;
      background-color: transparent;
      transition: all 0.3s;
      span{
        background-color: $navbar-collapsed-span;
        transition: all 0.3s;
      }
    }
  }

  nav.navbar{
    padding: 3em 2.5em 1em;
    &.navbar-default{
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid $navbar-default-border;
      border-radius: 0;
      margin-bottom: 0;
      .navbar-nav{
        font-family: $robotocondensed;
        font-weight: 700;
        font-size: 1.1em;
        text-transform: uppercase;
        &>li{
          a{
            padding: 0.5em 2em;
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
          }
          &:last-child{
            &>a{
              border-color: $header-login-link-border;
            }
          }
          &.register {
            margin-left: 10px;
            margin-right: 10px;
            a {
              background: $header-register-link-bg;
              color: $white;
            }
          }
        }
      }
    }
    .navbar-collapse{
      border-top: 0;
      box-shadow: none;
    }
  }
  .jumbotron{
    background-color: transparent;
    padding-top: 100px;
    p{
      font-weight: 300;
      font-size: 2.6em;
      padding-bottom: 1em;
      b{
        font-weight: 400;
      }
    }
    .btn{
      &.btn-lg{
        padding: 0.4em;
        font-size: 2.3em;
      }
      &.btn-default{
        background-color: transparent;
        border-color: $jumbotron-button-bg;
        color: $jumbotron-button-bg;
        transition: all 0.3s;
        &:hover{
          background-color: $jumbotron-button-bg;
          color: $white;
          transition: all 0.3s;
        }
      }
    }
  }
}

.home {
  .main-header {
    background-image: url('../images/header-picture.jpg');
    min-height: 100vh;
    nav.navbar {
      padding-bottom: 10px !important;
      &.navbar-default {
        border-bottom: 0;
        padding-bottom: 10px !important;
      }
    }
  }
}
