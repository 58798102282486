.modal {
  .modal-dialog {
    margin-top: 80px;
  }
  .modal-title {
    font-family: Roboto Slab,serif;
    font-size: 19px;
    font-weight: 700;
  }
  .modal-content {
    border-radius: 0;
  }
  form {
    input {
      height: 50px;
    }
    input[type=checkbox] {
      height: 13px;
    }
    button {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-bottom: 15px;
    }
    button[type=button] {
      padding: 0;
      a {
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }
  &#registerModal {
    .modal-body {
      .nav-tabs {
        & > li {
          width: 50%;
        }
        li {
          a {
            font-weight: 700;
            font-size: 19px;
          }
          &#tasker {
            a {
              color: $modal-reg-tasker-nav-color;
              &:hover, &:focus {
                color: $white;
                background-color: $modal-reg-tasker-nav-color;
              }
            }
            &.active {
              a {
                background-color: $modal-reg-tasker-nav-color;
                color: $white;
              }
            }
          }
          &#client {
            a {
              color: $modal-reg-client-nav-color;
              &:hover {
                color: $white;
                background-color: $modal-reg-client-nav-color;
              }
            }
            &.active {
              a {
                background-color: $modal-reg-client-nav-color;
                color: $white;
              }
            }
          }
        }
      }
      input[type=submit] {
        background: $modal-reg-submit-bg;
        border-color: $modal-reg-submit-border;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.modal {
  .register {
    max-width: 500px;
    h2 {
      font-family: $robotoslab;
      font-weight: bold;
      font-size: 50px;
      text-transform: none;
      letter-spacing: 0;
    }
    p {
      font-family: $robotoslab;
      font-size: 24px;
    }
    form {
      max-width: 385px;
      margin: 0 auto;
      .form-group {
        input {
          border-radius: 0;
        }
        &.left {
          padding-left: 0;
          padding-right: 5px;
        }
        &.right {
          padding-right: 0;
          padding-left: 5px;
        }
      }
      .terms {
        float: left;
        label {
          float: right;
          padding-left: 10px;
        }
      }
      input[type=submit] {
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: $register-submit-bg;
        border-color: $register-submit-border;
      }
    }
    &.client {

    }
    &.tasker {

    }
  }
}

#myModal {
  .modal-dialog {
    margin-top: 80px;
  }
  form {
    max-width: 500px;
    margin: 0 auto;
    .form-group {
      input {
        border-radius: 0;
      }
    }
  }
}