body {
  color: $dark-gray;
  font-family: $robotoslab;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $robotocondensed;
}

h2 {
  font-size: 2.6em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  small {
    color: inherit;
    clear: both;
    padding: 1em 0;
    display: block;
    width: 100%;
  }
}

.color {
  &.brand-primary {
    color: $robotocondensed;
  }
  &.light-red {
    color: $client-red;
  }
  &.dark-yellow {
    color: $tasker-yellow;
  }
  &.white {
    color: $white;
  }
  &.light-gray {
    color: $light-gray;
  }
  &.dark-gray {
    color: $dark-gray;
  }
  &.light-blue {
    color: $logo-blue;
  }
}

.btn {
  border-width: 3px;
  border-radius: 0;
  font-family: $robotocondensed;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%;
  &.btn-edit {
    border: 2px solid $edit-btn-border;
    color: $edit-btn-color;
  }
  &.btn-appliers {
    border: 2px solid $appliers-btn-border;
    background: $appliers-btn-bg;
    color: $white;
    margin-top: 15px;
  }
}